import { readable, derived } from 'svelte/store'
import { csv } from "d3"
import autoType from '@/utils/autoType.js';


// const yAccessors = {
//   ghg_per_pop: (d => d.pop ? d.ghg / d.pop : null),
//   ghg_per_gdp: (d => d.gdp ? d.ghg / d.gdp : null),
//   energy_per_pop: (d => d.pop ? d.energy / d.pop : null),
//   energy_per_gdp: (d => d.gdp ? d.energy / d.gdp : null),
//   ghg_per_energy: d => d.ghg / d.energy
// }

const yAccessors = { // these are provided in millions
  gdp: d => d.gdp * 1000000,
  pop: d => d.pop * 1000000
}

const processGHGData = dataset => {
  let data = dataset.map(autoType)
  // Calculate fields in the interim
  data.forEach(d => {
  for (let [key, acc] of Object.entries(yAccessors)){
    d[key] = acc(d);
  }
  })
  return data
}


export const ghgData = readable(null, (set) => { // 2nd argument is the start function, when the number of subscribers goes from 0 to 1.
  csv('data/data.csv').then(data => { // set can't be an async function or it errors out when unsubscribing. see: https://github.com/sveltejs/svelte/issues/4765#issuecomment-949296491
    set(processGHGData(data))
  })
  return () => {} // return empty stop function
})

export const kayaData = readable(null, (set) => {
  csv('data/data_kaya.csv').then(data => {
    set(data.map(autoType))
  })
  return () => {}
})

const get = (data, sector, year, fieldAccessor, scenario) => {
  const relevantData = data ? data.filter(d => d.sector === sector && d.year == year && d.scenario === scenario) : []
  return relevantData.length > 0 ? fieldAccessor(relevantData[0]) : undefined
}

export const getValue = derived(
  ghgData, $ghgData => ((sector, year, fieldAccessor = (d => d['ghg']), scenario = "historical") => get($ghgData, sector, year, fieldAccessor, scenario))
)