<script>
  // Import the getContext function from svelte
  import { getContext } from 'svelte';

  // Access the context using the 'LayerCake' keyword
  // Grab some helpful functions
  const { data, xGet, yGet, zGet, z, xScale, yScale, custom, width } = getContext('LayerCake');

  export let r = 2;
  export let scaled = false;
  export let fill = "#000";
  export let stroke = '#0cf';
  export let strokeWidth = 0;
  export let dx = 0;
  export let dy = 0;
  export let opacity = 1;

  $: radius = scaled ? r : (r / $width) * 100;

</script>

<g class="dot-group">
  {#each $data as group}
    <g class={group.key}>
      {#each group.values as d}
      <!-- Make sure to not plot it if not a number -->
        {#if !isNaN(+$yGet(d))}
        <circle
          cx={$xGet(d) + (typeof dx === 'function' ? dx($xScale) : dx)}
          cy={$yGet(d) + (typeof dy === 'function' ? dy($yScale) : dy)}
          r={radius}
          fill={$custom.getDotFormat(d)['fill'] ? $custom.getDotFormat(d)['fill'] : fill}
          stroke={$custom.getDotFormat(d)['stroke'] ? $custom.getDotFormat(d)['stroke'] : stroke}
          stroke-width={strokeWidth}
          fill-opacity={$custom.getDotFormat(d)['opacity'] ? $custom.getDotFormat(d)['opacity'] : opacity} />
          {/if}
        {/each}
    </g>
  {/each}
  </g>
