<script>

  export let url = "https://climateinstitute.ca/";
  export let label = "Climate Institute";
  export let target = "_self";
  export let highlighted = false;
  export let direction = "right";
  export let textColor = "var(--white)";
  export let activeColor = "var(--default)";
  export let fixedWidth;
  export let centerText = false;

  // assume px if fixedWidth is a number
  // should really make a generic css length validator
  if (typeof fixedWidth === "number") fixedWidth = fixedWidth + 'px';

</script>

<div class="parent">
  <div 
    class={"u-arrow-link mod-arrow-" + direction} 
    style="
      --bkgd-color: {highlighted ? activeColor : textColor}; 
      --text-color: {highlighted ? textColor : activeColor}; 
      {fixedWidth ? `min-width: ${fixedWidth};` : ''}
      {centerText ? 'text-align: center;' : ''}

    "
  >
    <a href={url} {target}>{label}</a>
  </div>
</div>

<style>

.parent {
  font-size: 10px;
}

.u-arrow-link {
  font-weight: 800;
  background: var(--bkgd-color);
  color: var(--text-color);
  font-weight: 800;
  letter-spacing: 0.01em;
  line-height: 1;
  margin: 0 0 .4em 0;
  text-transform: uppercase;
  width: fit-content;
  padding: 0;
  /* set a minimum height and center */
  min-height: 35px;
  display: flex;
  align-items: center;
}
.u-arrow-link a {
  background: inherit;
  border-radius: 0;
  color: inherit;
  display: inline-block;
  padding: 0;
  text-decoration: none;
  width: 100%;
}
.u-arrow-link a:focus {
  text-decoration-line: underline overline;
}

@media (max-width: 749px) {
  .u-arrow-link {
    font-size: 1.1em;
  }
  .u-arrow-link a {
    padding: 0.7em 0.6em 0.5em;
  }
  .u-arrow-link.mod-arrow-right a {
    padding-right: 1.2em;
  }
  .u-arrow-link.mod-arrow-right {
    clip-path: polygon(0% 0%, calc(100% - 1.2em) 0%, 100% 50%, calc(100% - 1.2em) 100%, 0% 100% );
  }
  .u-arrow-link.mod-arrow-left {
    padding-left: 1.2em;
  }
  .u-arrow-link.mod-arrow-left {
    clip-path: polygon(100% 100%, 1.2em 100%, 0% 50%, 1.2em 0%, 100% 0%);
  }
}
@media (min-width: 750px) {
  .u-arrow-link {
    font-size: 1.2em;
    font-weight: 800;
  }
  .u-arrow-link a {
    padding: 1.1em 1em 0.9em;
  }
  .u-arrow-link.mod-arrow-right a {
    padding-right: 1.6em;
  }
  .u-arrow-link.mod-arrow-right {
    clip-path: polygon(0 0,calc(100% - 1.6em) 0,100% 50%,calc(100% - 1.6em) 100%,0 100%);
  }
  .u-arrow-link.mod-arrow-left a {
    padding-left: 1.6em;
  }
  .u-arrow-link.mod-arrow-left {
    clip-path: polygon(100% 100%, 1.6em 100%, 0% 50%, 1.6em 0%, 100% 0%);
  }
}



  
</style>