<script>
  import mq from "@/stores/mq.js";
  import DownloadIcon from "./assets/DownloadIcon.svelte";
  import * as langHandler from '@/utils/language-handler.js';

  export let title;
  export let units;
  export let chartOffsetLeft = 0;
  export let chartOffsetRight = 0;
</script>

<div class="wide-bg">
    <div class="chart-section">
      <div class="title">{title ? title : "Title"}</div>
      <slot name="sector-dropdown"></slot>
      {#if !$mq.laptop}
        <slot name="legend"></slot>
        <div class="description-wrapper">
          <slot name="descriptions"></slot>
        </div>
      {/if}
      <div class="units">{units ? units : ""}</div>
      <div class="grid-container">
        <div class="main-chart" style="margin-left:{chartOffsetLeft}; margin-right:{chartOffsetRight};"><slot name="chart"></slot></div>
        {#if $mq.laptop}
          <div class="aside flex-wrapper">
            <slot name="descriptions"></slot>
            <slot name="legend"></slot>
          </div>
        {/if}
      </div>
      <div class="source"><slot name="source"></slot></div>
      <p class="caption"><slot name="caption"></slot></p>
      <div class="download-wrapper">
        <span on:click|stopPropagation={e => window.open(langHandler.getLink('downloadfile'), "_blank")} class="download-icon"><DownloadIcon fill="currentColor"/></span>
        <a class="download-link" href="{langHandler.getLink('downloadfile')}" target="_blank">{langHandler.getTranslation('download_data')}</a>
      </div>
    </div>
</div>

<style>
  .wide-bg {
    background-color: var(--bg-chart);
    padding-top: 32px;
    padding-bottom: 64px;
    padding-left: var(--padding);
    padding-right: var(--padding);
  }

  .chart-section {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--text-width);
  }

  .title {
    font-family: var(--sans);
    font-weight: 600;
    margin-bottom: 0;
    font-size: 1.17rem;
  }
  .units {
    margin-top: 16px;
    margin-bottom: 0px;
    font-size: 0.9rem;
    font-weight: 500;
  }

  .description-wrapper {
    margin-top: 12px;
  }

  .grid-container {
    display: grid;
    column-gap: 16px;
    grid-template-columns: minmax(100%,var(--text-width)) 11rem;
    /* align-content: flex-start; */
    grid-template-areas: "main aside";
  }

  .main-chart {
    grid-area: main;
    /* grid-column: 1; */
  }

  .aside {
    grid-area: aside;
    /* grid-column: 2; */
  }

  .flex-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .source {
    font-size: 0.75rem;
    font-style: italic;
    margin-top: 32px;
  }

  .caption {
    font-family: var(--sans);
    max-width: var(--text-width);
    margin: 0 auto;
    margin-top: 32px;
    font-size: 0.9rem;
  }

  .download-wrapper {
    margin-top: 32px;
    display: flex;
  }

  .download-wrapper .download-icon {
    display: inline-block;
    cursor: pointer;
  }
  .download-wrapper .download-link { 
    display: inline-block;
    font-size: 0.8rem;
    margin-left: 8px;
  }
</style>