<script>
  import * as langHandler from '@/utils/language-handler.js';
  import ImportedFooter from "./ImportedFooter.svelte";
  import DownloadIcon from "./assets/DownloadIcon.svelte";
</script>


<div class="download-footer">
  <div class="centered-text">
    <div class="download-wrapper">
      <span on:click|stopPropagation={e => window.open(langHandler.getLink('downloadfile'), "_blank")} class="download-icon"><DownloadIcon fill="currentColor"/></span>
      <a class="download-link" href="{langHandler.getLink('downloadfile')}" target="_blank">{langHandler.getTranslation('download_all')}</a>
    </div>
  </div>
</div>
<div class="voila-credit">
  <div class="centered-text">
    {#if langHandler.locale == 'fr'}
      Design et visualisations par <a href="https://chezvoila.com/fr" target="_blank" rel="noopener noreferrer">Voilà:</a>
    {:else}
      Design and visualizations by <a href="https://chezvoila.com/" target="_blank" rel="noopener noreferrer">Voilà:</a>
    {/if}
  </div>
</div>
<ImportedFooter />

<style>
  .download-footer {
    width: 100%;
    background-color: var(--default);
    color: white;
    padding-left: var(--padding);
    padding-right: var(--padding);
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .centered-text {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--text-width);
  }
  .download-wrapper {
    display: flex;
    /* width: max-content; */
    width: 100%;
    align-items: center;
  }

  .download-wrapper .download-icon {
    display: inline-block;
    cursor: pointer;
    width: 1.2rem;
    height: 1.2rem;
  }

  .download-wrapper .download-link { 
    display: inline-block;
    font-size: 0.8rem;
    margin-left: 8px;
  }

  .voila-credit {
    display: inline-block;
    font-size: 0.825rem;
    font-style: italic;
    width: 100%;
    padding-top: 32px;
    padding-bottom: 32px;
    color: white;
    background-color: #627e83;
    font-weight: 500;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .voila-credit .centered-text {
    max-width: 39rem;
  }

</style>