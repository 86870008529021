import { readable } from "svelte/store";

// See App.svelte for CSS variables
const padding = 32;
const textWidth = 600;
const sideMenuWidth = 160; // an estimate from inspecting

const breakpoints = {
  smallMobileMax: 375,
  mobileMax: 500,
  useLegendLabelsAt: 600,
  tabletMin: 700,
  laptopMin: textWidth + 2*(padding+sideMenuWidth) // 1100,
}

const queries = {
  "smallMobile": `(max-width: ${breakpoints.smallMobileMax}px)`,
  "mobile": `(max-width: ${breakpoints.mobileMax}px)`,
  // "between": `(min-width: ${breakpoints.mobileMax + 1}px) and (max-width: ${breakpoints.laptopMin - 1}px)`,
  "tablet": `(min-width: ${breakpoints.tabletMin}px) and (max-width: ${breakpoints.laptopMin - 1}px)`,
  "useLegendLabels": `(max-width: ${breakpoints.useLegendLabelsAt}px)`,
  "laptop": `(min-width: ${breakpoints.laptopMin}px)`,
  "shortDesktop": `(max-height: 500px) and (min-width: ${breakpoints.mobileMax}px)`,
  // "mobile": `(max-width: ${breakpoints.mobileMax / 16}rem)`,
  // "laptop": `(min-width: ${breakpoints.laptopMin / 16}rem)`,
  // "shortDesktop": `(max-height: 500px) and (min-width: ${breakpoints.mobileMax / 16}rem)`,
  "tooSmall": "(max-height: 300px) and (max-width: 300px)",
	// "sm": "(max-width: 767px)",
	// "md": "(min-width: 768px) and (max-width: 1023px)",
	// "lg": "(min-width: 1024px)",
	// "xl": "(min-width: 1280px)",
	"reducedMotion": "(prefers-reduced-motion: reduce)"
};

function calculateMedia(mqls) {
	const media = { classNames: '' };
	const mediaClasses = [];
	for (let name in mqls) {
		media[name] = mqls[name].matches;
		if (media[name]) mediaClasses.push(`mq-${name}`);
	}
	media.classNames = mediaClasses.join(" ");
	return media;
}

export default readable({}, (set) => {
	if (typeof window === "undefined") return;
	const mqls = {};
	const onChange = () => set(calculateMedia(mqls));
	
	if (typeof window !== "undefined") {
		for (let q in queries) {
			mqls[q] = window.matchMedia(queries[q]);
			mqls[q].addListener(onChange);
		}

		onChange();
	}
	
	return () => {
		for (let q in mqls) {
			mqls[q].removeListener(onChange);
		}
	};
});
