import SECTORS from '@/data/data_sectors.csv'
import { years } from '@/constants.js'
import { locale, getTranslation } from '@/utils/language-handler.js'
import { format, formatDefaultLocale } from 'd3-format';

export function usePopAsActivity(id) {
  const popAsActivity = SECTORS.filter(d => d.sector === id)[0].populationAsActivity
  return (popAsActivity == "TRUE" || popAsActivity == "true" || popAsActivity == true)
}

export function getKayaValue(data, driver, endYear, sector, projection, fieldAccessor = (d => d['ghgChange'])){
  if (data){
    const scenario = endYear < years.projection[0] ? "historical" : projection
    const relevantData = data.filter(d => d.sector === sector && d.scenario === scenario && d.endYear == endYear && d.effect == driver)
    return relevantData.length > 0 ? fieldAccessor(relevantData[0]) : undefined
  } else {
    return undefined
  }
}

export const addNZPRange = (data, keys) => { // Note: key can be a string of the variable of interest, or an array of strings
  let copy = data.map(x => x)
  const keyList = Array.isArray(keys) ? keys : [keys] // Make array of keys provided 
  copy.filter(d => d.scenario == "NZP_lower").forEach((d) => {
    let row = {... d};
    row.scenario = "NZP_range";
    for (let key of keyList){
      let lower = d[key];
      let upper = data.filter(r => r.scenario == "NZP_upper" && r.year == d.year)[0][key];
      row[key] = [lower, upper];
    }
    copy.push(row)
  })
  return copy
}

export const localeFormatter = formatDefaultLocale({ // this also re-defines d3-format
  thousands: locale == 'fr' ? "\u00a0" : ',',
  decimal: locale == 'fr' ? ',' : '.',
  grouping: [3],
  currency: [locale === 'fr' ? '' : '$', ''],
  percent: locale == 'fr' ? "\u00a0%" : '%'
})

export const formatDefaultAxis = format(',')

export const formatDefaultVal = d => Math.abs(d) < 100 ? format(".1f")(d) : format(',d')(d);

const siFormat = format('_<.3~s');
export const formatBigNumber = (number, currency=false) => {
  const res  = locale == 'fr' ? siFormat(number).replace(/[A-Za-z]/gi, x => "\u00a0"+x) : siFormat(number).replace('G', 'B').replace('k', 'K');
  if (currency === true){
    return locale == 'fr' ? res + '$' : '$' + res;
  } else {
    return res;
  }
}


export const formatBigNumberLong = (number, currency=false) => {
  const res  = siFormat(number).replace('T', ' ' + getTranslation('trillion')).replace('G', ' ' + getTranslation('billion')).replace('M', ' ' + getTranslation('million')).replace('k', getTranslation('thousand_abbr'));
  if (currency === true){
    return locale == 'fr' ? res + '\u00a0$' : '$' + res;
  } else {
    return res;
  }
}


export const formatBigNumberDollars = d => formatBigNumber(d, true);
export const formatBigNumberLongDollars = d => formatBigNumberLong(d, true);

export const formatPercentageTracker =  d => format('.0%')(d); 