<script>
  import { getContext } from 'svelte';
  const { xScale, yScale } = getContext('LayerCake'); 

  export let y1;
  export let y2;
  export let x;
  export let xPx;
  export let width = 5;
  export let dx = 12;
  export let strokeWidth = 2;
  
  export let stroke = "#aaa"

  export let orientation = "right";

  $: mL = xPx ? xPx + dx : $xScale(x) + dx;
  $: lL = orientation == "right" ? mL + width : mL - width;

</script>

  <path class="bracket" fill="none" {stroke} stroke-width={strokeWidth}
    d="M{mL},{$yScale(y1)} L{lL},{$yScale(y1)} L{lL},{$yScale(y2)} L{mL},{$yScale(y2)}"/>

     