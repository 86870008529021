<script>
  import mq from "@/stores/mq.js";
  import * as langHandler from '@/utils/language-handler.js';
  import { trimArticle } from '@/utils/language-handler.js';

</script>

<div class="legend-container {$mq.classNames}"> <!-- class:horizontal={!$mq.laptop} -->
  <div class="legend-title">{langHandler.getTranslation("kaya_legend_title")}</div>
  <div class="legend-item-wrapper">
    <div class="legend-item">
      <div class="name activity">{trimArticle(langHandler.getTranslation("activity"))}</div>
      <div class="description">{@html langHandler.getDescription("activity")}</div>
    </div>
    <div class="legend-item">
      <div class="name efficiency">{trimArticle(langHandler.getTranslation("efficiency"))}</div>
      <div class="description">{@html langHandler.getDescription("efficiency")}</div>
    </div>
    <div class="legend-item">
      <div class="name cleaner">{trimArticle(langHandler.getTranslation("cleaner"))}</div>
      <div class="description">{@html langHandler.getDescription("cleaner")}</div>
    </div>
  </div>

</div>

<style>
  .legend-container {
    /* background-color: var(--bg); */
    border-radius: var(--border-curve);
    margin-left: 4px;
    padding-right: 6px;
    color: var(--default);
  }

  .mq-mobile.legend-container {
    padding: 0;
  }

  .horizontal .legend-title{
    /* padding-left: 4px; */
    display: none;
  }

  .legend-title {
    margin-bottom: 8px;
    font-size: 0.75rem;
    font-weight: 500; 
  }
  .mq-laptop .legend-title {
    font-size: 0.8rem;
    margin-bottom: 16px;
  }

  .horizontal .legend-item-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }
  .legend-item {
    margin-left: 8px;
    margin-bottom: 8px;
  }

  .mq-laptop .legend-item {
    margin-bottom: 12px;
  }

  .mq-tablet .legend-item {
    display: flex;
    gap: 16px;
  }

  .horizontal .legend-item {
    width: 100%;
    margin-left: 4px;
  }

  .legend-item .name {
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
    position: relative;
  }

  .legend-item .description {
    font-size: 0.7rem;
    font-style: italic;
    font-weight: 400;
  }
  
  .mq-tablet .legend-item .description, .mq-laptop .legend-item .description {
    font-size: 0.725rem;
  }

  .legend-item .name::before {
    content: "";
    display: inline-block;
    background-color: currentColor;
    border-radius: 2px;
    width: 4px;
    height: 1.1rem;
    position: absolute;
    left: -10px;
  }

  .activity {
    color: var(--orange-medium);
  }

  .efficiency {
    color: var(--blue);
  }
  
  .cleaner {
    color: var(--turquoise-medium);
  }

</style>
