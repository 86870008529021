export const locale = _app.env.lang

// Getting translations
import LANG from '@/data/translation.json' // importing like this bundles the data up into the js file.

export const NZPtooltip = LANG['NZPtooltipText']

export function getTranslation(key) {
  return LANG[key]
}

export function getProjectionName(key) {
  return LANG.projectionNames[key]
}

export function getSectorName(key) {
  return LANG.sectorNames[key]
}

export function getDescription(key) {
  return LANG.descriptions[key]
}

export function getUnits(key) {
  return LANG.units[key]
}

export function getLink(key){
  return LANG.links[key]
}

export const trimArticle = s => {
  if (typeof s === 'string'){
    const sep = s.split(/ |'|’/) // create an array by snipping at spaces and apostrophes
    return sep[sep.length - 1]
  } else {
    return s
  }
}
// Constructing sentences
const isCanada = d => (d.id.toLowerCase() == "canada" || d.id.toLowerCase() == "national")

export function formatSectorSentence(d, includeDe=false){
  if (locale == 'fr'){
    if (includeDe){
      return isCanada(d) ? 'du Canada' : `du secteur de <strong>${d.name?.toLowerCase()}</strong>` 
    } else{
      return isCanada(d) ? 'le Canada' : `le secteur de <strong>${d.name?.toLowerCase()}</strong>` 
    }
  } else {
    return isCanada(d) ? 'Canada' : `the <strong>${d.name?.toLowerCase()}</strong> sector` 
  }
}