<script>
  import { Switch } from "@rgossiaux/svelte-headlessui";

  export let enabled = false;
  export let srLabel = "Enable";
  export let label;
  export let labelPosition = 'left';
</script>

{#if labelPosition !== 'right'}
  <span class="toggle-label {labelPosition}" on:click={() => {enabled = !enabled}}>{label ? label : ""}</span>
{/if}
<Switch
  checked={enabled}
  on:change={(e) => (enabled = e.detail)}
  class={enabled ? "switch switch-enabled" : "switch switch-disabled"}
>
  <span class="sr-only">{srLabel}</span>
  <span class="toggle" class:toggle-on={enabled} class:toggle-off={!enabled} />
</Switch>
{#if labelPosition === 'right'}
  <span class="toggle-label {labelPosition}" on:click={() => {enabled = !enabled}}>{label ? label : ""}</span>
{/if}

<style>
  :global(.switch) {
    position: relative;
    display: inline-flex;
    align-items: center;
    border-radius: 9999px;
    height: 0.9rem;
    width: 2rem;
    cursor: pointer;
    border: none;
  }

  :global(.switch-enabled) {
    /* Blue */
    background-color: hsla(177, 30%, 60%);
  }

  :global(.switch-disabled) {
    /* Gray */
    /* background-color: rgb(229 231 235); */
    background-color: var(--grey-light); 
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .toggle {
    display: inline-block;
    position: relative;
    top: -0.05rem;
    width: 1rem;
    height: 1rem;
    background-color: white;
    /* background-color: rgb(255 255 255); */
    /* background-color: var(--grey); */
    border-radius: 9999px;
    box-shadow: 0px 2px 2px var(--grey);
  }

  .toggle-on {
    transform: translateX(1rem);
    background-color: var(--neutral);
  }

  .toggle-off {
    transform: translateX(0rem);
  }

  .toggle-label {
    cursor: pointer;
    line-height: 1;
  }

  .toggle-label.left {
    margin-right: 8px;
  }

  .toggle-label.right {
    margin-left: 8px;
  }
</style>