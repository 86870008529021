<script>
  import url from "@/utils/url";
  import Overview from "./components/Overview.svelte";
  import Activity from "./components/Activity.svelte"
  import Efficiency from "./components/Efficiency.svelte"
  import Decarbonization from "./components/Decarbonization.svelte"


</script>

<div class="page-container">
  {#if $url.hash === "" || $url.hash === "#/"}
    <Overview />
  {:else if $url.hash === "#/activity"}
    <Activity />
  {:else if $url.hash === "#/efficiency"}
    <Efficiency />
  {:else if $url.hash === "#/decarbon"}
    <Decarbonization />
  {:else}
    <Overview />
  {/if}
</div>

<style>

  .page-container {
    padding-top: 32px;
    padding-bottom: 64px;
  }

  * :global(.text-wrapper) {
    padding-left: var(--padding);
    padding-right: var(--padding);
  }
  * :global(.centered-text) {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--text-width);
  }
</style>
