export const textWrap = function(text, lineWidth = 25, lineSpacing = 1.2, center = false) {
  let words = text.split(/\s+/).reverse()
  let tsvs = [];
  const totalLines = Math.ceil(text.length/lineWidth);
  let line = []
  let word;
  let n = 1;

  const fontSize = 10;

  const addLine = () => {
    let y = center ? - (totalLines/2 - n + 0.5) * fontSize  * lineSpacing : (n-1) * fontSize  * lineSpacing; 
    tsvs.push({label: line.join(" "), y: y});
  }
  
  do {
    word = words.pop();
    line.push(word);
    if (line.join(" ").length > lineWidth) {
      line.pop();
      addLine();
      line = [word];
      n++;
    }
  } while(word)
  if (line[0]){
    addLine();
  }

  return tsvs
}
