<!--
  @component
  Generates an SVG area shape using the `area` function from [d3-shape](https://github.com/d3/d3-shape).
 -->
<script>
  import { getContext } from 'svelte';
  import { area, curveLinear } from 'd3-shape';

  const { data, xGet, yGet, zGet, yScale, custom} = getContext('LayerCake');

  /** @type {Function} [curve=curveLinear] - An optional D3 interpolation function. See [d3-shape](https://github.com/d3/d3-shape#curves) for options. Pass this function in uncalled, i.e. without the open-close parentheses. */
  export let curve = curveLinear;

  let defaultFill = '#ab00d610';

  $: path = area()
      .x($xGet)
      .y1(d => $yGet(d)[1])
      .y0(d => $yGet(d)[0]) //.y0(d => $yScale(0))
      .curve(curve);
    // .defined($y)
</script>

<g class="area-group">
  {#each $data as group}
    {#if $custom.zLookup[group.key] && $custom.zLookup[group.key]['area']}
      <path class='path-area' d='{path(group.values)}' 
      fill={$custom.zLookup[group.key]['fill'] ? $custom.zLookup[group.key]['fill'] : defaultFill}
      opacity={$custom.zLookup[group.key]['opacity'] ? $custom.zLookup[group.key]['opacity'] : 0.8}>
      </path>
    {/if}
  {/each}
</g>