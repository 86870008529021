<!--
  @component
  Generates HTML text labels for a nested data structure. It places the label near the y-value of the highest x-valued data point. This is useful for labeling the final point in a multi-series line chart, for example. It expects your data to be an array of objects where each has `values` field that is an array of data objects. It uses the `z` field accessor to pull the text label.
 -->
<script>
  import { getContext } from 'svelte';
  import { merge, max, mean } from 'd3-array';

  const { data, x, y, xScale, yScale, xRange, yRange, z} = getContext('LayerCake');

  export let formatKey = d => d;

  $: top = values => $yScale(mean(values));
  const getValues = g => merge(g.filter(d => d != null))
  const getFirstValue = g => g[0]

  // $: tooClose = Math.abs(top(getValues($data[0])) - top(getValues($data[1]))) < 12
  // $: offset = i => i > 0 && tooClose ? top(getValues($data[0])) + 24 : 0;

  $: offset = values => {
    return Math.abs($yScale(mean(values)) - $yRange[0]) < 12 ? -12 : 0;
  }

</script>

<!-- Important that the data is sorted from highest to lowest, so we can use the index properly -->
{#each  $data.sort((a, b) => (a[0][0] < b[0][0] ? 1 : -1)) as series, i}
  <div
  class="label {series.key}"
  style="
    top:{top(getFirstValue(series)) + offset(getFirstValue(series))}px;
    left:{0};
  "
  >{formatKey(series.key)}</div>
{/each}

<style>
  .label {
    position: absolute;
    font-size: 0.75rem;
    /* padding-top: 1em; */
    padding-left: 1em;
    font-weight: 600;
    transform: translateY(-50%);
  }

  .share_emitting {
    color: var(--grey-dark);
  }

  .share_nonemitting {
    color: var(--turquoise-dark);
  }
</style>