<script>
  import Dropdown from "./Dropdown.svelte";
  import { selectedSector, sectorOptions } from '@/stores/interactivity.js'

</script>

<Dropdown options={$sectorOptions} bind:selectedOption={$selectedSector} titleSize={true}/>

<style>
</style>
