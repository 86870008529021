<script>
  import * as langHandler from '@/utils/language-handler.js';
  import { trimArticle } from '@/utils/language-handler.js';

  import ArrowLink from "./ArrowLink.svelte";

  export let page = "overview";

</script>


<div class="text-wrapper">
  <div class="spacer"/>
  <div class="centered-items">
    <div class="left">
      {#if page === 'activity'}
        <ArrowLink 
          label={langHandler.getTranslation('overview')}
          url='#/'
          direction='left'
          activeColor='var(--dark)'
          highlighted=true
          fixedWidth='10rem'
          centerText=true
        />
      <!-- {:else}
        <div>Explore the drivers of GHG emissions</div> -->
      {/if}
      {#if page === 'efficiency'}
        <ArrowLink 
          label={trimArticle(langHandler.getTranslation('activity'))}
          url='#/activity'
          direction='left'
          activeColor='var(--orange)'
          highlighted=true
          fixedWidth='10rem'
          centerText=true
        />
      {/if}
      {#if page === 'decarbon'}
        <ArrowLink 
          label={trimArticle(langHandler.getTranslation('efficiency'))}
          url='#/efficiency'
          direction='left'
          activeColor='var(--blue)'
          highlighted=true
          fixedWidth='10rem'
          centerText=true
        />
      {/if}

    </div>
    <div class="right">
      {#if page === 'overview'}
        <ArrowLink 
          label={trimArticle(langHandler.getTranslation('activity'))}
          url='#/activity'
          activeColor='var(--orange)'
          highlighted=true
          fixedWidth='10rem'
          centerText=true
        />
      {/if}
      {#if page === 'activity'}
        <ArrowLink 
          label={trimArticle(langHandler.getTranslation('efficiency'))}
          url='#/efficiency'
          activeColor='var(--blue)'
          highlighted=true
          fixedWidth='10rem'
          centerText=true
        />
      {/if}
      {#if page === 'efficiency'}
        <ArrowLink 
          label={trimArticle(langHandler.getTranslation('cleaner'))}
          url='#/decarbon'
          activeColor='var(--turquoise)'
          highlighted=true
          fixedWidth='10rem'
          centerText=true
        />
      {/if}
      {#if page === 'decarbon'}
        <ArrowLink 
          label={langHandler.getTranslation('overview')}
          url='#/'
          activeColor='var(--dark)'
          highlighted=true
          fixedWidth='10rem'
          centerText=true
        />
      {/if}
    </div>
  </div>
</div>


<style>

  .centered-items {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--text-width);
  }

  .left {
    width: 50%;
    display: flex;
    align-self: flex-end;
  }
  .right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    float: right;
  }

  .spacer {
    height: 4rem;
  }

</style>