<script>
  import * as langHandler from '@/utils/language-handler.js';
  import { trimArticle } from '@/utils/language-handler.js';
  // import { onMount } from "svelte";
  import url from "@/utils/url";
  // import logo from "@/img/logo.svg"

  import mq from "@/stores/mq.js";

  import ImportedHeader from './ImportedHeader.svelte'

  let fullWidth, menuWidth;

  // onMount(async () => {
  // });


</script>


<ImportedHeader/>
<div class="top-bar" bind:clientWidth={fullWidth}>
  <nav class="nav-wrapper {$mq.classNames}" bind:clientWidth={menuWidth}>
   
      <!-- {#if !$mq.mobile} -->
        <!-- <div class="nav-item label">Explore drivers:</div> -->
        <!-- <div class="spacer" style="width: 50px;"/> -->
      <!-- {/if}  -->
      <div class="nav-item menu-link overview" class:active={$url.hash === "" || $url.hash === "#/"} on:click|stopPropagation={e => window.open("#/", "_self")} style="order: 4">
        <a href="#/" aria-current={$url.hash === "#" || $url.hash === "" ? "page" : undefined}>{langHandler.getTranslation('overview')}</a>
      </div>
      <div class="nav-item menu-link activity" class:active={$url.hash === "#/activity"} style="order: 3" on:click|stopPropagation={e => window.open("#/activity", "_self")}>
        <a href="#/activity" aria-current={$url.hash === "#/activity" ? "page" : undefined}>{trimArticle(langHandler.getTranslation('activity'))}</a>
      </div>
      <div class="nav-item menu-link" class:active={$url.hash === "#/efficiency"} style="order: 2" on:click|stopPropagation={e => window.open("#/efficiency", "_self")}>
        <a href="#/efficiency" aria-current={$url.hash === "#/efficiency" ? "page" : undefined}>{trimArticle(langHandler.getTranslation('efficiency'))}</a>
      </div>
      <div class="nav-item menu-link" class:active={$url.hash === "#/decarbon"} style="order: 1" on:click|stopPropagation={e => window.open("#/decarbon", "_self")}>
        <a href="#/decarbon" aria-current={$url.hash === "#/decarbon" ? "page" : undefined}>{trimArticle(langHandler.getTranslation('cleaner'))}</a>
      </div>
  </nav>
</div>

<style>
  .imported-header {
    background: hsla(160, 29%, 90%, 1);
    height: 70px;
    position: relative;
    z-index: var(--z-top);
  }

  .top-bar {
    padding-top: 8px;
    background-color: var(--default);
    color: white;
    align-content: center;
    position: sticky;
    top: 0;
    z-index: var(--z-top);
    font-size: 0.75rem;
    padding-left: 16px;
    padding-right: 16px;
  }
  .nav-wrapper {
    height: 100%;
    width: 100%;
    max-width: max-content;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    align-items: flex-end;
    grid-column-gap: 12px;
  }

  .nav-item {
    padding: 8px;
    /* align-self: center; */
    align-self: end;
    color: white;
    font-weight: 300;
    border-bottom: 3px solid transparent;
    white-space: nowrap;
  }

  .nav-item.label {
    opacity: 0.7;
    padding-right: 8px;
  }

  a {
    text-decoration: none;
  }
  
  .menu-link {
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 500;
  }

  .menu-link.active, .menu-link:hover {
    border-bottom: 3px solid hsla(160, 29%, 90%, 1);
  }
  .menu-link.active a {
    font-weight: 600;
    /* text-decoration: underline; */
  }

  .activity {
    margin-left: 12px;
  }

  
  @media (max-width:630px){
    .nav-wrapper {
      margin: 0 0 0 auto;
    }
    .top-bar {
      padding-left: 60px;
    }
  }

  @media (max-width: 500px){
    .menu-link {
      font-weight: 600;
    }
  }

</style>
