<script>
  import { getContext } from 'svelte';
  import { format } from 'd3-format';

  const { data, width, x, y, yScale, zScale, z, config, custom } = getContext('LayerCake');

  const commas = format(',');

  export let visible = false;
  export let evt = {};

  export let tooltipOffset = 20;
  export let formatTitle = (d) => d;
  export let formatKey = (d) => d;
  export let formatValue = (d) => (isNaN(+d) ? d : commas(d));

  export let footnote;

  /// !!! LayerX and LayerY don't behave the same in all browsers. Use ClientX and ClientY instead, and position it relative to viewport.
  $: tooltipX = evt.detail ? evt.detail.e.clientX : 0;
  $: tooltipY = evt.detail ? evt.detail.e.clientY : 0;

  $: d = evt.detail ? evt.detail.props : {};

  const yKey = $custom.yKey // or $r(d) - $y(d) Keeping this different than ArrowLabels in case any errors can be caught this way


  const w = 150;

</script>

  {#if visible === true}
    <!-- <div style="left:{tooltipX}px;" class="line" /> -->
    <div
      class="tooltip custom"
      style="
        width:{w}px;
        display: {visible ? 'block' : 'none'};
        top:{tooltipY - tooltipOffset}px;
        left:{tooltipX}px;">
        <div class="title">{formatTitle($z(d))}</div>
        <div class="row">
          <div class="key">{formatKey($x(d))}:</div>
          <div class="value">{formatValue(d[yKey])}</div>
        </div>
        {#if footnote}
          <div class="footnote">{footnote}</div>
        {/if}
    </div>
  {/if}

<style>
  .tooltip {
    position: fixed;
    font-size: 0.7rem;
    pointer-events: none;
    /* border: 1px solid #ccc; */
    background: rgba(255, 255, 255, 0.85);
    transform: translate(-50%, -100%);
    padding: 5px;
    z-index: 15;
    pointer-events: none;
  }

  .tooltip.custom {
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: var(--border-curve);
    background: rgba(255, 255, 255, 0.95);
  }
  .line {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    border-left: 1px dotted var(--default);
    pointer-events: none;
    opacity: 0.5;
  }
  /* .tooltip,
  .line {
    transition: left 250ms ease-out, top 250ms ease-out;
  } */
  .title {
    /* font-weight: bold; */
    font-size: 0.7rem;
  }

  .row:not(:last-child) {
    margin-bottom: 8px;
  }

  .key {
    line-height: 1.2;
  }

  .value {
    font-weight: 600;
    font-size: 0.75rem;
  }

  .footnote {
    font-style: italic;
    line-height: 1.2;
  }

</style>
