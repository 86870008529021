<script>
  import { getContext } from 'svelte';

  const {
    data,
    xGet,
    yGet,
    zGet,
    xScale,
    yScale,
    xRange,
    yRange,
    xDomain,
    yDomain,
    custom,
    height,
    padding
  } = getContext('LayerCake');

  export let x0;
  export let x1;
  export let fill = "#999";
  export let opacity = 0.5;
  export let classes = "";
  export let linePlacement = "none";
  export let lineStroke = "#FFF"

  $: linepos = linePlacement === "right" ? $xScale(x1) : (linePlacement === "left" ?  $xScale(x0) : null)
</script>

<!-- Have the rectangle go all the way to the top, covering the padding area -->
<g class="highlight-group">
  <rect class="highlighted-area {classes}" 
    {fill} {opacity}
    x={$xScale(x0)} y={-$padding.top} 
    width={Math.abs($xScale(x1)-$xScale(x0))}
    height={$height + $padding.top}/>
  <line class="highlight-divider" x1={linepos} x2={linepos} y1={-$padding.top} y2={$height}
    stroke={lineStroke} />
</g>

<style>
</style>
